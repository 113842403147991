@import './Colors.scss';

.Header {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.HeaderLeft {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.HeaderNavLink {
    font-size: 0.75rem;
    line-height: 50px;
    color: $color-light-primary;
}

.Dark .HeaderNavLink {
    color: $color-dark-primary;
}

.HeaderLeft .HeaderNavLink {
    margin: 0 0 0 1rem;
}

.HeaderNavLink:hover {
    color: $color-light-accent !important;
}

.HeaderNavLink:visited {
    color: $color-light-primary;
}

.IsActive .HeaderNavLink {
    color: $color-light-accent;
}

.Dark .HeaderNavLink:hover {
    color: $color-dark-accent !important;
}

.Dark .HeaderNavLink:visited {
    color: $color-dark-primary;
}

.Dark .IsActive .HeaderNavLink {
    color: $color-dark-accent;
}

a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
}
