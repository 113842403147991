@import './Colors.scss';
@import './Fonts.scss';
@import './Width.scss';

.About {
    display: flex;
    flex-direction: row;
    margin: 4rem 0 4rem 0;
}

.About .Personal {
    display: flex;
    flex-direction: column;
    vertical-align: top;
    padding: 0 3rem 0 0;
}

.About .Personal img {
    object-fit: cover;
    border-radius: 1rem;
    height: 16rem;
    width: 16rem;
}

.AboutTitle {
    font-family: $font-secondary;
    font-size: 3rem;
    color: $color-light-accent;
}

.Dark .AboutTitle {
    color: $color-dark-accent;
}

.Description {
    font-size: 1rem;
    line-height: 2rem;
}

.AboutSubTitle {
    font-family: $font-secondary;
    font-size: 2rem;
    color: $color-light-accent;
}

.Dark .AboutSubTitle {
    color: $color-dark-accent;
}

.SkillsContainer {
    margin: 1rem 0 2rem 0;
}

.SkillsType {
    margin: 1rem 0 8px 0;
    display: block;
    font-family: $font-secondary;
    font-size: 1rem;
    color: $color-light-primary;
}

.Dark .SkillsType {
    color: $color-dark-primary;
}

.Skill {
    font-size: 0.75rem;
    display: inline-block;
    position: relative;
    padding: 0 16px 0 16px;
    height: 2rem;
    line-height: 2rem;
    border-radius: 1rem;
    background-color: rgba($color-light-primary, 0.1);
    margin: 8px 8px 8px 0;
}

.Skill svg {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

.Skill span {
    margin-left: 2rem;
}

@media only screen and (max-width: $breakpoint-l) {
    .About {
        margin: 0 0 0 0;
        flex-direction: column;
    }

    .About .Personal {
        width: calc(100vw - 2rem);
        height: calc(100vw - 2rem);
        padding: 0 0 0 0;
        margin: 0 0 2rem 0;
    }

    .About .Personal img {
        width: 100%;
        height: 100%;
    }
}

.Dark .Skill {
    background-color: rgba($color-dark-primary, 0.1);
}
