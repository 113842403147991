@import './Colors.scss';

.Footer {
    padding: 16px;
    display: flex;
}

.FooterWrapper {
    text-align: center;
    margin: 0 auto;
    padding: 4rem 0;
}

.FooterLink {
    font-size: 0.75rem;
    margin: 0 8px;
    line-height: 50px;
    color: $color-light-primary;
}

.Dark .FooterLink {
    color: $color-dark-primary;
}

.FooterLink:hover {
    color: $color-light-accent !important;
}

.FooterLink:visited {
    color: $color-light-primary;
}

.Dark .FooterLink:hover {
    color: $color-dark-accent !important;
}

.Dark .FooterLink:visited {
    color: $color-dark-primary;
}

.LastUpdated {
    font-size: 0.75rem;
    color: $color-light-secondary;
}

.Dark .LastUpdated {
    color: $color-dark-secondary;
}
