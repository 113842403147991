$color-light-background: #fff;
$color-light-primary: rgba(#1f1f1f, 1);
$color-light-secondary: rgba(#1f1f1f, 0.64);
$color-light-accent: #4285f4;
$color-light-highlight: rgba(#f4b400, 0.32);

$color-dark-background: #121212;
$color-dark-primary: rgba(#fff, 0.8);
$color-dark-secondary: rgba(#fff, 0.64);
$color-dark-accent: #4285f4;
$color-dark-highlight: rgba(#f4b400, 0.32);

:root {
    --color-light-background: #{$color-light-background};
    --color-light-primary: #{$color-light-primary};
    --color-light-secondary: #{$color-light-secondary};
    --color-light-accent: #{$color-light-accent};
    --color-light-highlight: #{$color-light-highlight};
    --color-dark-background: #{$color-dark-background};
    --color-dark-primary: #{$color-dark-primary};
    --color-dark-secondary: #{$color-dark-secondary};
    --color-dark-accent: #{$color-dark-accent};
    --color-dark-highlight: #{$color-dark-highlight};
}
