@import './Colors.scss';
@import './Fonts.scss';
@import './Width.scss';

.Preface {
    display: flex;
    flex-direction: row;
    margin: 4rem 0 4rem 0;
}

.PrefaceProfile {
    display: flex;
    flex-direction: column;
    vertical-align: top;
    // justify-content: center;
    padding: 0 3rem 0 0;
}

.PrefaceProfile img {
    object-fit: cover;
    border-radius: 1rem;
    height: 16rem;
    width: 16rem;
}

.PrefaceText {
    padding: 0 0 2rem 0;
    color: $color-light-primary;
}

.Dark .PrefaceText {
    color: $color-dark-primary;
}

.PrefaceText > span,
.PrefaceText > a {
    font-family: $font-secondary;
    font-size: 3rem;
    text-align: left;
}

.SophosChild > p {
    font-size: 1rem;
    margin: 0 0 0 1rem;
}

.ExperienceContainer > div > p {
    font-family: $font-secondary;
    font-size: 2rem;
    color: $color-light-accent;
    margin-bottom: 0;
}

.Details {
    flex-direction: column;
    padding-left: 6rem;
    padding-bottom: 0.25rem;
}

.HomeNavLink {
    color: $color-light-primary;
}

.HomeNavLink:visited {
    color: $color-light-primary;
}

.HomeNavLink:hover {
    color: $color-light-accent !important;
}

.IsActive .HomeNavLink {
    color: $color-light-accent;
}

a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
}

@media only screen and (max-width: $breakpoint-l) {
    .Preface {
        margin: 0 0 0 0;
    }

    .PrefaceProfile {
        display: none;
    }

    .Details {
        padding-left: 5rem;
    }

    .SophosChild {
        margin: 1rem 0 0.5rem 4rem !important;
    }
}

.SophosChild {
    margin: 1rem 0 0.5rem 6rem;
}

@media only screen and (min-width: $breakpoint-l) {
    .SophosChild:nth-of-type(n+2)::before {
        content: "";
        position: absolute;
        height: 3rem;
        width: 2px;
        background-color: rgba($color-light-primary, 0.5);
        margin-left: calc(-3rem - 1px);
        margin-top: -3.25rem;
    }

    .Dark .SophosChild:nth-of-type(n+2)::before {
        background-color: rgba($color-dark-primary, 0.5);
    }

    .SophosChild::after {
        content: "";
        position: absolute;
        height: 0.5rem;
        width: 0.5rem;
        background-color: rgba($color-light-primary, 0.75);
        margin-left: -3.25rem;
        margin-top: -3.25rem;
        border-radius: 50%;
    }

    .Dark .SophosChild::after {
        background-color: rgba($color-dark-primary, 0.75);
    }
}

.SophosChild > p:nth-child(1) {
    font-family: $font-secondary;
    color: rgba($color-light-primary, 0.75);
}

.SophosChild > p:nth-child(2),
.SophosChild > p:nth-child(3) {
    color: rgba($color-light-primary, 0.5);
}

.Dark .SophosChild > p:nth-child(1) {
    color: rgba($color-dark-primary, 0.75);
}

.Dark .SophosChild > p:nth-child(2),
.Dark .SophosChild > p:nth-child(3) {
    color: rgba($color-dark-primary, 0.5);
}

.Dark .HomeNavLink {
    color: $color-dark-primary;
}

.Dark .HomeNavLink:hover {
    color: $color-dark-accent !important;
}

.Dark .HomeNavLink:visited {
    color: $color-dark-primary;
}

.Dark .IsActive .HomeNavLink {
    color: $color-dark-accent;
}

.HomeHighlight {
    color: $color-light-accent !important;
}

.Dark .HomeHighlight {
    color: $color-dark-accent !important;
}
