@import './Colors.scss';
@import './Fonts.scss';
@import './Width.scss';

.ExperienceSection {
    display: block;
}

.ExperienceSectionTitle {
    font-family: $font-secondary;
    font-size: 2rem;
    color: $color-light-accent;
}

.Dark .ExperienceSectionTitle {
    color: $color-dark-accent;
}

.ExperienceSectionSubTitle {
    font-family: $font-secondary;
    font-size: 1rem;
    color: $color-light-primary;
}

.Dark .ExperienceSectionSubTitle {
    color: $color-dark-primary;
}

.ExperienceSectionBody {
    padding: 2rem 3rem 0 3rem;
    font-size: 1rem;
    line-height: 2rem;
}

.ExperienceSectionQuote {
    border-left: 0.2rem solid $color-light-secondary;
    color: $color-light-secondary;
    padding-left: 1rem;
    margin: 2rem 0 0 0;
}

.Dark .ExperienceSectionQuote {
    border-left: 0.2rem solid $color-dark-secondary;
    color: $color-dark-secondary;
}

@media only screen and (max-width: $breakpoint-l) {
    .ExperienceSectionBody {
        padding: 2rem 0 0 0;
    }

    img.ExperienceSectionBody {
        padding-left: 0;
        padding-top: 0;
        width: 100%;
    }
}
