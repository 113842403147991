@import './Colors.scss';
@import './Width.scss';

.SocialIcon {
    display: inline-block;
    margin: 0 2rem 0 0;
}

@media only screen and (max-width: $breakpoint-l) {
    .PrefaceBreak {
        display: none;
    }

    .SocialWrapper {
        margin: 0 0 1rem 0;
    }
}

#IconGithub,
#IconLinkedin,
#IconGmail {
    fill: $color-light-secondary;
}

.Dark #IconGithub,
.Dark #IconLinkedin,
.Dark #IconGmail {
    fill: $color-dark-secondary;
}

#IconGithub:hover {
    fill: #181717;
}

.Dark #IconGithub:hover {
    fill: white;
}

#IconLinkedin:hover {
    fill: #0077b5;
}

#IconGmail:hover {
    fill: #d14836;
}
