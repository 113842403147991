@import './Colors.scss';
@import './Fonts.scss';
@import './Width.scss';

.Experience {
    padding: 32px 0 0 0;
}

.ExperienceTitle {
    font-family: $font-secondary;
    font-size: 3rem;
    color: $color-light-accent;
    margin: 0 0 8px 0;
}

.Dark .ExperienceTitle {
    color: $color-dark-accent;
}

.ExperienceType {
    margin: 0 0 1rem 0;
    color: $color-light-secondary;
}

.Dark .ExperienceType {
    color: $color-dark-secondary;
}

.ExperienceChip {
    font-size: 0.75rem;
    display: inline-block;
    padding: 0 1rem;
    height: 32px;
    line-height: 2rem;
    border-radius: 1rem;
    border: 1px solid $color-light-secondary;
    margin: 0 8px 8px 0;
}

.Dark .ExperienceChip {
    border: 1px solid $color-dark-secondary;
}

.ExperienceVisit {
    background-color: transparent;
    color: $color-light-secondary;
}

.Dark .ExperienceVisit {
    color: $color-dark-secondary;
}

.ExperienceVisit:hover {
    background-color: transparent;
    color: $color-light-accent;
    border: 1px solid $color-light-accent;
}

.Dark .ExperienceVisit:hover {
    color: $color-dark-accent;
    border: 1px solid $color-dark-accent;
}

.ExperienceSkills {
    margin: 0 0 1rem 0;
}

.ExperienceSkills svg {
    margin: 0 8px 0 0;
}

.ExperienceDescription {
    margin: 2rem 0 2rem 0;
    font-size: 1rem;
    line-height: 2rem;
}

.Experience img {
    padding-left: 16rem;
    width: calc(100% - 32rem);
}
