@import '../Colors.scss';
@import '../Fonts.scss';
@import '../Width.scss';

.Card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;
    margin: 1rem 0;
    border-width: 0 1px 0 1rem;
    border-style: solid;
}

.CardContainer {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    order: 1;
}

.CardActionContainer {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: flex-end;
    order: 2;
}

.CardChildren {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    order: 3;
}

.CardImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem 0 1rem;
}

.CardImage {
    height: 4rem;
    width: 4rem;
}

.CardTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CardTitle {
    font-family: $font-secondary;
}

.CardTitle,
.CardDescription,
.CardDate {
    margin: 0;
    color: $color-light-primary;
}

.Dark .CardTitle,
.Dark .CardDescription,
.Dark .CardDate {
    color: $color-dark-primary;
}

.CardAction {
    background-color: transparent;
    border: none;
    border-radius: 1rem;
    font-size: 0.8rem;
    height: calc(1.8rem + 2px);
    line-height: 2rem;
    width: 8rem;
    margin: 0.25rem;
    color: $color-light-primary;
    text-align: center;
}

.Dark .CardAction {
    color: $color-dark-primary;
}

.CardAction:hover {
    background-color: rgba(0, 0, 0, 0.025);
    cursor: pointer;
}

@media only screen and (max-width: $breakpoint-l) {
    .Card {
        padding: 1rem;
        border-width: 0 0 0 0;
    }

    .CardContainer {
        flex: 1 0 100%;
    }

    .CardImageContainer {
        padding: 0 1rem 0 0;
    }

    .CardActionContainer {
        order: 4;
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
    }

    .CardAction {
        height: 3rem;
        line-height: 3rem;
        border-radius: 1.5rem;
        flex: 1;
    }
}
